export default {
  totalAmountMarginTop: '3rem',
  loanCalculatorBorderWidth: '1px',
  loanCalculatorValueColor: 'var(--rahoitu-orange-500)',
  summaryButtonSelectedColor: 'var(--black)',
  summaryButtonSelectedBackground: 'var(--rahoitu-orange-500)',
  userAgreementMarginTop: '.5rem',
  prefillBackroundColor: 'var(--vegas-gold-100)',
  validatedBrandColor: 'var(--rahoitu-orange-500)',
};
